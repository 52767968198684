import type { Enums, Tables } from '~~/types/supabase'
import type { AtLeast, Maybe } from '~~/types/utils'

type Section =
  | 'asset categories'
  | 'assets'
  | 'download logs'
  | 'email statistics'
  | 'product categories'
  | 'products'
  | 'retailers'
  | 'users'

export default function useACL() {
  const userPermissions = useState<Array<Enums<'app_permission'>>>(
    'rn_user_permissions',
    () => []
  )

  const user = useSupabaseUser()

  function isAdmin() {
    return userPermissions.value.includes('all')
  }

  function canCreate(section: Section) {
    switch (section) {
      case 'asset categories':
      case 'assets':
      case 'products':
      case 'retailers':
        return isAdmin()

      case 'users':
        return isAdmin() || userPermissions.value.includes('retailer.update') // Retailer Group Admins are able to invite new users

      default:
        // Default to no access if a section is not supported
        return false
    }
  }

  function canDelete(section: Section) {
    switch (section) {
      case 'asset categories':
      case 'assets':
      case 'products':
      case 'retailers':
      case 'users':
        return isAdmin()

      default:
        // Default to no access if a section is not supported
        return false
    }
  }

  function canEdit(section: Section) {
    switch (section) {
      case 'asset categories':
      case 'assets':
      case 'products':
      case 'users':
        return isAdmin()

      case 'retailers': // This check will only grant general access to retailer management actions, the `canEditRetailer` function must be used for full access checks
        return isAdmin() || userPermissions.value.includes('retailer.update')

      default:
        // Default to no access if a section is not supported
        return false
    }
  }

  /**
   * Determines if a user can edit the given retailer based on the presence of either the `retailer_group_admin_id` field
   * or the `retailer_group_admin` relational object
   */
  function canEditRetailer(
    retailer: AtLeast<Tables<'retailers'>, 'id'> & {
      retailer_group_admin?: Maybe<AtLeast<Tables<'profiles'>, 'user_id'>>
    }
  ) {
    if (isAdmin()) return true

    if (!canEdit('retailers') || !user.value) return false

    if (retailer.retailer_group_admin_id) {
      return user.value!.id === retailer.retailer_group_admin_id
    }

    if (retailer.retailer_group_admin) {
      return user.value!.id === retailer.retailer_group_admin!.user_id
    }

    // The required relationship data does not exist, so assume no access
    return false
  }

  function canView(section: Section) {
    switch (section) {
      case 'asset categories':
      case 'assets':
        // General access to assets and asset categories exists for everyone, the content itself is ACL restricted
        return true

      case 'products':
        return isAdmin() || userPermissions.value.includes('sales_rep.read')

      case 'retailers':
        return isAdmin() || userPermissions.value.includes('retailer.read')

      case 'download logs':
      case 'email statistics':
      case 'users':
        return isAdmin()

      default:
        // Default to no access if a section is not supported
        return false
    }
  }

  return { canCreate, canDelete, canEdit, canEditRetailer, canView }
}
